import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#E0FA88",
          // backgroundImage: `url(${require("assets/images/asset7.jpg")})`,
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row></Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center">
                <h2 className={`text-700 text-dark`}>
                  HERE'S WHAT OUR PARTICIPANTS HAVE TO SAY ABOUT <br /> VMF, MIDDLE EAST
                </h2>
              </div>
            </Col>

            <Col lg={12} xs={11} className="text-center">
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <h4
                      className="text-dark text-400 px-3"
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <br />
                    <h3 className="text-dark text-700">{c.name}</h3>
                    <p className="text-dark text-400">{c.title}</p>
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial:
      "The calibre of the speakers, the quality of the presentations, and the really engaging discussions made for an excellent conference and I highly recommend any marketer that wants to learn to really attend Vibe Martech Fest.” ",
    name: "DARELL ALFONSO",
    title: "Global Marketing Operations Leader – Amazon Web Service",
  },
  {
    testimonial:
      "It’s incredible to be back. It’s really amazing to see such a well-organised event with so many good players from the industry, being able to discuss great ideas, push the envelope forward and really move the needle for this industry. ",
    name: "JOE AKKAWI",
    title: "Senior Success Manager, MEA, Global Strategic Account – Sprinklr",
  },
  {
    testimonial:
      "My experience with Vibe Martech Fest in Dubai has been absolutely fantastic. It is so full of energy but also there’s a particular intimacy with the group here to talk about local issues as well as global ones.",
    name: "STEVE LOK",
    title: "Senior Director Martech,  Bain & Company",
  },
  {
    testimonial:
      "It’s quite an opportunity to hear about the marketing and tech stack, that many different companies have as well as the opportunities and challenges, ",
    name: "PRIYA PATEL-CHOMEL",
    title: "Head of Product Marketing, Middle East, Turkey and Africa (META) ",
  },
  {
    testimonial:
      "We’ve had a lot of great conversations and some fantastic companies come and speak to us who we’ve not spoken to before. ",
    name: "JENNIFER PETRIE",
    title: "Industry Lead, Sitecore",
  },
  {
    testimonial:
      "We’ve been here for years now and it’s great to see some of our customers, talk to new people, and learn more about what’s going on in the UAE especially, seeing the new technologies coming up. ",
    name: "DAVID JOHNSON",
    title: "Director of EMEA, ObservePoint",
  },
  {
    testimonial:
      "It’s been phenomenal. This has been my second time at the Vibe Martech Fest. It’s been great to meet the leading brands in the Middle East so yes, I definitely look forward to coming back again. ",
    name: "JESIKA DALAL",
    title: "Marketing Director,  Branch",
  },

  {
    testimonial:
      "Vibe Martech Fest is an incredible platform. I think it’s something that’s always been needed. There’s always a space needed for marketers to come, express, and learn all new things - some amazing talent and people talking here. I’m looking forward to next year. ",
    name: "MIKE RICH",
    title: "Chief Marketing Officer  – Aramex",
  },
  {
    testimonial:
      "The experience is great, this is something we need as marketers because we talk a lot about brands, communications, and then at the same time we are dealing with performance marketing. ",
    name: "Krzysztof Kowalski",
    title: "Chief Marketing Officer - YAP",
  },
];

export default Testimonials;
