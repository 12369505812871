import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Navbar from "components/Navbar.js";
import { sponsors } from "./content";
import NavbarTop from "../components/NavbarTop.js";
import Sponsor from "components/Sponsor";

function PrivacyPolicy() {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title="Past Sponsors" bgColor="#fff" />
      <Sponsor
        sponsors={sponsors.sort((a, b) => a.name.localeCompare(b.name))}
      />

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
