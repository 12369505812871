import React from "react";

// core components
import Navbar from "../components/Navbar.js";

// import MobileNavbar from "../components/Navbars/MobileNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import TextGrid3 from "components/TextGrid3.js";
import TextGrid4 from "components/TextGrid4.js";
import DemoFooter from "../components/Footer.js";
import Photos from "../components/Photos.js";

import Agenda from "../components/Agenda.js";
import Reasons from "../components/Reasons.js";

import SpeakerCard from "../components/SpeakerCard.js";
import SponsorNew from "../components/SponsorNew";
import Testimonials from "../components/Testimonials";

// import Venue from '../components/Venue';

import { news } from "./content";

import News from "components/News.js";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      {/* <NavbarTop /> */}
      <Navbar />
      <section id="home" />
      {/* <SaleHeader /> */}
      <LandingPageHeader />

      <TextGrid3 />
      <TextGrid4 />
      <div
        style={{
          // background: "rgb(242,254,190)",
          background: "linear-gradient(180deg, rgba(242,254,190,1) 14%, rgba(236,154,182,1) 95%)",
        }}
      >
        <section id="agenda">
          <Agenda />
        </section>
      </div>
      <section id="speakers">
        <SpeakerCard />
      </section>
      {/* <Reasons />
      <Photos /> */}

      <section id="partners">
        <SponsorNew />
      </section>
      <Testimonials />
      <section id="news" />
      <News news={news} />

      <DemoFooter />
    </>
  );
}

export default Home;
