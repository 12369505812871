import React from 'react';
import Navbar from 'components/Navbar.js';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader.js';

import Footer from 'components/Footer.js';
import { Container, Row, Col } from 'reactstrap';
import NavbarTop from 'components/NavbarTop.js';

function PaymentRedirect({
  match: {
    params: { id },
  },
}) {
  return (
    <>
      <NavbarTop />
      <Navbar />
      {id === 'CAPTURED' ? (
        <>
          <ProfilePageHeader title={`REGISTERED SUCCESSFULLY`} bgColor="#fff" />
          <div className="section profile-content" style={{ padding: 0, background: '#64ffda' }}>
            <Container>
              <Row>
                <Col lg={12} className="text-center text-dark p-5 m-5">
                  <h3>
                    <>
                      Thank you for registering your interest to the Vibmartechfest Europe. A
                      member of our team will be in touch with you for further details about the
                      event.
                    </>
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      ) : (
        <>
          <ProfilePageHeader title={`PAYMENT FAILED`} bgColor="#fff" />
          <div className="section profile-content" style={{ padding: 0, background: '#ef9a9a' }}>
            <Container>
              <Row>
                <Col lg={12} className="text-center text-dark p-5 m-5">
                  <h3>
                    <>
                      The Payment has failed with the Status {id}. Please try again, or if you are
                      facing any issues please connect with our team on marketing@martechvibe.com
                    </>
                  </h3>
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}

      <Footer />
    </>
  );
}

export default PaymentRedirect;
