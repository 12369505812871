import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import { sponsors } from "views/content";

function Sponsor() {
  const rows = [["PRESENTING SPONSOR"], ["DIGITAL EXPERIENCE PARTNER"], ["GOLD SPONSOR"]];
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          background: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-700 text-center mb-5" style={{ color: "#000" }}>
                PAST EDITION SPONSORS
              </h2>
            </Col>
          </Row>
          {/* {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s) => (
                  <Col lg={s.grid || 4} xs={s.smallGrid || 6} className={"ml-auto mr-auto pb-3"}>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <p
                        className="text-muted text-center text-400 mb-2"
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: !size && "12px",
                        }}
                      >
                        {s.type}
                      </p>
                      <Card style={{ boxShadow: "none" }} className="rounded-0">
                        <Container fluid>
                          <Row>
                            <Col lg={12}>
                              <img
                                src={require(`assets/sponsors/${s.image}`)}
                                alt={s.name}
                                width="100%"
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))} */}
          <Row>
            {sponsors
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((s) => (
                <Col lg={3} xs={6} className={"ml-auto mr-auto pb-3"}>
                  <a href={`/sponsors/${s.pageLink}`}>
                    <Container fluid>
                      <Row>
                        <Col lg={12}>
                          <img
                            src={require(`assets/sponsors/${s.image}`)}
                            alt={s.name}
                            className="rounded-0"
                            width="100%"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </a>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
