import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SponsorAll from "components/SponsorAll.js";
import Navbar from "components/Navbar.js";
import { sponsorsPage } from "./content";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="Sponsors & Partners" bgColor="#fff" />
      <SponsorAll sponsors={sponsorsPage} />

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
