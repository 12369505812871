import React, { useCallback, useState } from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import ImageViewer from "react-simple-image-viewer";

import Slider from "react-slick";

function News({ news }) {
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const ims = images.map((n) => require(`assets/photos/${n}`));
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section pt-2"
        data-parallax={true}
      >
        <Container fluid>
          <Row className="justify-content-center">
            <Col lg={12} className="text-dark text-center mb-5 ">
              <h2 className=" text-700 text-uppercase" style={{ color: "#000" }}>
                Highlights of CX NXT UAE 2022
              </h2>
            </Col>

            <Col lg={11} className={"ml-auto mr-auto mb-4"}>
              <Slider {...settings}>
                {images.map((n, index) => (
                  <img
                    src={require(`assets/photos/${n}`)}
                    width="100%"
                    alt="event"
                    className="px-1"
                    onClick={() => openImageViewer(index)}
                  />
                ))}
              </Slider>
            </Col>
          </Row>
          {isViewerOpen && (
            <ImageViewer
              src={ims}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
        </Container>
      </div>
    </>
  );
}

export default News;

const images = [
  "2.jpg",
  "3.jpg",
  "4.jpg",
  "5.jpg",
  "6.jpg",
  "7.jpg",
  "8.jpg",
  "9.jpg",
  "10.jpg",
  "11.jpg",
  "12.jpg",
  "13.jpg",
  "14.jpg",
  "15.jpg",
  "16.jpg",
  "17.jpg",
  "18.jpg",
  "19.jpg",
  "20.jpg",
  "21.jpg",
  "22.jpg",
  "23.jpg",
  "24.jpg",
  "25.jpg",
  "26.jpg",
  "27.jpg",
  "28.jpg",
  "29.jpg",
  "30.jpg",
  "31.jpg",
  "32.jpg",
  "33.jpg",
  "34.jpg",
  "35.jpg",
  "36.jpg",
  "37.jpg",
  "38.jpg",
  "39.jpg",
  "40.jpg",
  "41.jpg",
  "42.jpg",
  "43.jpg",
  "44.jpg",
  "45.jpg",
  "46.jpg",
  "47.jpg",
  "48.jpg",
];
