import { DataContext } from "DataContainer";
import moment from "moment";
import React, { useContext } from "react";
import {
  // Collapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Collapse,
  Card,
} from "reactstrap";
import { groupBy } from "views/content";

function Agenda() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [open, toggleOpen] = React.useState({
    open: -1,
  });
  const { agenda } = useContext(DataContext);
  const dayWiseAgenda = groupBy("day")(agenda);

  function AgendaCard({ data }) {
    const size =
      document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
        ? true
        : false;
    return data.map((d, index) => (
      <Container>
        <Row>
          <Col lg={2} className="align-self-center">
            <h4 className="header-text mt-0 text-dark">
              <b>{moment.utc(d.startAt).format("HH:mm")}</b>{" "}
              {!size && d.description && (
                <i
                  className="fa fa-plus-circle ml-3"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleOpen(open === index ? -1 : index)}
                  aria-hidden="true"
                ></i>
              )}
            </h4>
          </Col>
          <Col lg={9} className="align-self-center text-dark  py-4">
            <h5 className="text-400 m-0">
              <b>{d.title}</b>
            </h5>
            {d.text &&
              d.text.map((t) => (
                <h3 className="text-300 m-0 mt-1" dangerouslySetInnerHTML={{ __html: t }} />
              ))}
          </Col>
          {size && d.description && (
            <>
              <Col lg={1} className="align-self-center">
                <h3 className="text-400 mt-0 text-dark">
                  <i
                    class="fa fa-plus-circle"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleOpen(open === index ? -1 : index)}
                  ></i>
                </h3>
              </Col>
              <Col lg={2} />
            </>
          )}
          <Col lg={9}>
            <Collapse isOpen={open === index}>
              <p
                className="text-400 text-primary"
                dangerouslySetInnerHTML={{ __html: d.description }}
              />
            </Collapse>
          </Col>
        </Row>
      </Container>
    ));
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#DDD1EF",
        }}
        className="section pb-5"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col lg={12}>
              <h2 className="text-700 text-dark text-center mb-5">AGENDA</h2>
            </Col>
            <Col md="12" style={{ backgroundColor: "#DDD1EF" }}>
              <div className="nav-tabs-navigation text-left mt-3 mb-3">
                <div className="nav-tabs-wrapper">
                  <Nav tabs className="nav-fill">
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 text-dark ${
                          activeTab === "1" ? "active" : ""
                        }`}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <h4 className="text-400 mt-0 pl-3">
                          <b>DAY 1</b>
                        </h4>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 text-dark ${
                          activeTab === "2" ? "active" : ""
                        }`}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <h4 className="text-400 mt-0 pl-3">
                          <b>DAY 2</b>
                        </h4>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col md="12" className="bg-white">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={(dayWiseAgenda[1] || []).filter((f, i) => i < 5)} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={(dayWiseAgenda[2] || []).filter((f, i) => i < 5)} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="pt-3 text-center">
              <Button
                href="/request-agenda"
                className="rounded-0 text-400 px-3 mr-2 border-0"
                style={{
                  background: "#000003",
                }}
                size="lg"
              >
                <span
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  REQUEST FULL AGENDA
                </span>
              </Button>{" "}
              <Button
                href="/pricing-options"
                className="rounded-0 text-400 px-4 border-0"
                style={{
                  background: "#000003",
                }}
                size="lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                >
                  REGISTER
                </span>
              </Button>
            </Col>
            <Col lg={12} className="pt-3 text-center"></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Agenda;
