import React from 'react';
import Countdown from 'react-countdown';
import { Button } from 'reactstrap';

function ProfilePageHeader({ title, bgColor }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  return (
    <>
      <div
        style={{
          backgroundColor: '#ff687d',
        }}
        className="py-3 fixed-bottom d-flex justify-content-center"
        data-parallax={true}
      >
        <h5 className="text-center text-white text-700 my-0 align-self-center mr-3">
          {size ? (
            <>
              Sale ends in{" "}
              <Countdown date={new Date(1652444924000)} renderer={renderer} />
            </>
          ) : (
            <>
              Sale ends in <br />
              <Countdown date={new Date(1652444924000)} renderer={mobileRenderer} />
            </>
          )}
        </h5>
        <Button
          href="/pricing-options"
          className="navbar-item-custom text-400 text-white px-4 rounded-0"
          color="danger"
          style={{ backgroundColor: '#092e82' }}
          size="lg"
        >
          <span
            style={{
              color: '#fff',
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '16px',
            }}
            className="header-text"
          >
            BOOK NOW
          </span>
        </Button>
      </div>
    </>
  );
}

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return 'SALE ENDED';
  }
  return (
    <>
      <span style={{ fontSize: '30px', fontWeight: 700 }}>{days} </span>Days :{' '}
      <span style={{ fontSize: '30px', fontWeight: 700 }}>{hours} </span> Hours :{' '}
      <span style={{ fontSize: '30px', fontWeight: 700 }}>{minutes} </span> Minutes :{' '}
      <span style={{ fontSize: '30px', fontWeight: 700 }}>{seconds} </span>Seconds
    </>
  );
};
const mobileRenderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return 'SALE ENDED';
  }
  return (
    <>
      <span style={{ fontSize: '20px', fontWeight: 700 }}>{days}D </span> :{' '}
      <span style={{ fontSize: '20px', fontWeight: 700 }}>{hours}H </span>  :{' '}
      <span style={{ fontSize: '20px', fontWeight: 700 }}>{minutes}M </span>  :{' '}
      <span style={{ fontSize: '20px', fontWeight: 700 }}>{seconds}S </span>
    </>
  );
};

export default ProfilePageHeader;
