import React from "react";

import { Container, Row, Col, Card } from "reactstrap";
// import CountUp from 'react-countup';
import ScrollAnimation from "react-animate-on-scroll";

import "react-circular-progressbar/dist/styles.css";

function AboutPoints() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        className="section py-2"
        style={{
          backgroundColor: "transparent",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="text-dark text-center " md={12}>
              <h2 className="text-700 text-dark text-center mb-5">WHY CX & WHY NOW?</h2>
            </Col>
          </Row>
          <Row className={`justify-content-center ${size ? "row-cols-5" : "row-cols-1"}`}>
            {content.map((c) => (
              <Col
                className="d-flex p-1"
                style={{
                  minHeight: size ? "320px" : "0",
                }}
              >
                <div className={`mb-0  p-3 text-dark`}>
                  <h1
                    className="text-900  mt-0"
                    style={{ color: "#7dfba2", fontSize: size ? "4.5rem" : "3rem" }}
                  >
                    {c.id}%
                  </h1>
                  {/* <h5 className="text-700 mt-0">{c.title}</h5> */}
                  <p className="text-400 mt-0">{c.description}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutPoints;

const content = [
  {
    title: "Develop a 'Data First' Strategy",
    description: "of customers are more likely to buy from companies that deliver custom content. ",
    id: 61,
  },

  {
    title: "Develop a 'Data First' Strategy",
    description:
      "of Customer-centric companies are more profitable than companies that don’t focus on customers.",
    id: 60,
  },
  {
    title: "Develop a 'Data First' Strategy",
    description: "of customers have taken action as a result of one bad experience",
    id: 95,
  },
  {
    title: "Develop a 'Data First' Strategy",
    description:
      "of customers will switch to a competitor after one bad experience, In the case of more than one that number snowballs to 80%",
    id: 50,
  },
  {
    title: "Develop a 'Data First' Strategy",
    description:
      "of CX affects impulse purchases of buyers who make spontaneous purchases after a more personalised experience.",
    id: 49,
  },
];
