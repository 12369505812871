import React from "react";
import { Col, Card, Table, Input, Button } from "reactstrap";
import CreateAlert from "./Alert";

function OrderInfo({
  price,
  details,
  applyCoupon,
  customer,
  onChangePromo,
  promoApplied,
  error,
  success,
  removeCoupon,
}) {
  return (
    <>
      <Col md="6">
        <Card>
          <Table>
            <thead>
              <tr>
                <th>Pricing Option</th>
                <th>Base Price</th>
                <th>Service Fee (2.5%)</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span className="text-600">{details.description}</span>
                  <br />
                </td>
                <td>
                  <h3 className="mt-0">${Math.round(price / 1.025)}</h3>
                </td>
                <td>
                  <h3 className="mt-0">${(price - price / 1.025).toFixed(2)}</h3>
                </td>
                <td>
                  <h3 className="text-600 mt-1">${parseFloat(price).toFixed(2)}</h3>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>{details.body}</td>
              </tr>
            </tbody>
          </Table>
          <div className="p-3 d-flex w-100">
            <Input
              type="text"
              name="promo"
              id="promo"
              value={customer.promo || ""}
              onChange={onChangePromo}
              disabled={promoApplied}
              placeholder="Promo Code"
              className="w-50 mr-3"
            />

            {promoApplied ? (
              <Button color="danger" className="align-self-end ml-1" onClick={removeCoupon}>
                <i class="fa fa-times"> </i>Remove Promo
              </Button>
            ) : (
              <Button
                className="align-self-end"
                onClick={applyCoupon}
                disabled={promoApplied}
                style={{ background: "#274fe4", border: 0 }}
              >
                Apply Promo Code
              </Button>
            )}
          </div>
        </Card>
        <CreateAlert success={success} error={error} />
      </Col>
    </>
  );
}

export default OrderInfo;
