import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

function Sponsor({ sponsors }) {
  const rows = [
    // ["PRESENTING SPONSOR"],
    // ["DIGITAL EXPERIENCE PARTNER"],
    // ["GOLD SPONSOR"],
    // ["SILVER SPONSOR"],
    // ["EXHIBITOR"],
    // ["ASSOCIATE SPONSOR"],
    // ["VISITING SPONSOR"],
  ];
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section"
        data-parallax={true}
      >
        <Container>
          {rows.map((row) => (
            <Row>
              {row.map((sponsor) =>
                sponsors[sponsor].map((s) => (
                  <Col lg={s.grid || 4} className={"ml-auto mr-auto pb-3"}>
                    <a href={`/sponsors/${s.pageLink}`}>
                      <p
                        className="text-muted text-center text-400 mb-2"
                        style={{
                          margin: 0,
                          padding: 0,
                          fontSize: !size && "12px",
                        }}
                      >
                        {s.type}
                      </p>
                      <Card style={{ boxShadow: "none" }} className="rounded-0">
                        <Container fluid>
                          <Row>
                            <Col lg={12}>
                              <img
                                src={require(`assets/sponsors/${s.image}`)}
                                alt={s.name}
                                width="100%"
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Card>
                    </a>
                  </Col>
                ))
              )}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
