import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Register from "components/Register";
import Footer from "components/Footer.js";
import SponsorAll from "components/SponsorAll.js";
import { sponsorsPage } from "./content";
import NavbarTop from "../components/NavbarTop.js";

function RegisterPage({ type, title, subject }) {
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type={type} subject={subject} />
      </div>
      {/* <ProfilePageHeader
        title={
          type === "SPONSOR"
            ? "BE A PART OF OUR 2023 SPONSORS & PARTNERS LIST"
            : "2022 SPONSORS & PARTNERS"
        }
        bgColor="#fff"
        noPadding
      />
      <SponsorAll sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
