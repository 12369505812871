import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SpeakerCardAll from "components/SpeakerCardAll.js";
import Navbar from "components/Navbar.js";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="2023 Speakers" bgColor="#fff" />
      <SpeakerCardAll />

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
