import React, { useState } from "react";
import { GoSell } from "@tap-payments/gosell";
import LoadingOverlay from "react-loading-overlay";
import { Redirect } from "react-router";
import { apiURL, token } from "../../constant";

const PaymentRedirect = () => {
  const [redirect, setRedirect] = useState(false);
  const [status, setStatus] = useState(false);
  if (redirect && status) {
    return <Redirect to={`/payment-redirect/${status}`} />;
  }

  const callback = (response) => {
    const data = response.callback;
    const body = {
      ...data.metadata,
      udf: {
        ...JSON.parse(data.metadata.udf),
        transactionId: data.reference.transaction,
        orderId: data.reference.order,
        status: data.status,
      },
      passes: ["DAY1", "DAY2"],
      utmSource: data.metadata.utmSource === "null" ? null : data.metadata.utmSource,
    };
    fetch(`${apiURL}/setDelegateData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setStatus(data.status);
        setRedirect(true);
      })
      .catch(() => {
        setStatus(data.status);
        setRedirect(true);
      });
  };

  return (
    <LoadingOverlay active={true} spinner text="Loading please do not refresh this page.">
      <div className="App">
        <GoSell
          gateway={{
            callback: callback,
          }}
        />
      </div>
    </LoadingOverlay>
  );
};

export default PaymentRedirect;
