import React from "react";

import { Container, Row, Col, Button, Badge } from "reactstrap";

const content1 = {
  id: 1,
  title: "DESIGNATIONS",
  values: [
    "Customer Experience ",
    "Customer Service & Customer Loyalty",
    "Marketing ",
    "Digital Transformation",
    "Customer Insights",
    "UX, Analytics",
    "Market Intelligence ",
    "Rewards",
    "Contact Centres",
    "Service Design",
    "CRM ",
    "Retention & Growth",
  ],
  color: "#422e6e",
};
const content2 = {
  id: 2,
  title: "FOCUS INDUSTRIES",
  values: [
    "Banking & Finance ",
    "Retail ",
    "Healthcare ",
    "Real Estate ",
    "Government Services",
    "Telecom ",
    "FMCG",
    "Internet Companies",
    "eCommerce ",
    "Service Design",
    "Tourism, Hospitality",
  ],
  color: "#f17f30",
};

function KeyNotes() {
  return (
    <>
      <div
        className="section pb-0"
        style={{
          backgroundColor: "transparent",
        }}
      >
        {" "}
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h2 className="text-700 m-0 my-4 text-dark text-left">FOCUS INDUSTRIES</h2>
              {content2.values.map((c) => (
                <Badge style={{ backgroundColor: "#aff888" }} pill key={c} className="mb-2 mr-2">
                  <p className="text-400 m-0 text-dark">{c}</p>
                </Badge>
              ))}
              <h2 className="text-700 m-0 my-4 text-dark text-left mt-5">WHO WILL ATTEND</h2>
              <h4 className="mt-0 text-400 mb-4">
                The agenda is carefully designed to inspire attendees and promises to be an
                unmissable opportunity for CXOs, CMOs, CIOs, COOs, VPs / Heads / Directors / General
                Managers / Managers of -
              </h4>
              {content1.values.map((c) => (
                <Badge color="primary" pill key={c} className="mb-2 mr-2">
                  <p className="text-400 m-0">{c}</p>
                </Badge>
              ))}
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}></Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
