/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
// import "assets/css/bootstrap.min.css";

import "assets/css/paper-kit.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import "assets/demo/demo.css";

import Home from "./views/home.js";
import RegisterPage from "./views/Register.js";
import ThankYouRegister from "./views/ThankYouRegister";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Termsconditons from "./views/Termsconditions";

import PastSpeakers from "./views/PastSpeakers";
import PastSponsors from "./views/PastSponsors";

import Speakers from "./views/Speakers";
// import Award from "./views/Award";
import Resource from "./views/Resource";
import SponsorSingle from "./views/SponsorSingle";
import Sponsors from "./views/Sponsors";
import Agenda from "./views/Agenda.js";
import OnDemand from "./views/OnDemand.js";
import Insights from "./views/Insights.js";
import About from "views/About.js";
import NewsSingle from "views/NewsSingle.js";
import KeyThemes from "views/KeyThemes.js";
import PaymentRedirect from "views/PaymentProcess/PaymentRedirect.js";
import PaymentRedirectStatus from "views/PaymentProcess/PaymentRedirectStatus.js";
import PriceCardPage from "views/PaymentProcess/PriceCardPage.js";

import PaymentProcess from "views/PaymentProcess";

import Colin from "views/Colin";
import DataProvider from "./DataContainer";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route
          path="/register"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="REGISTER"
              title="REGISTER NOW"
              subject="VMF EU Register Request"
            />
          )}
        />
        <Route
          path="/request-brochure"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="BROCHURE"
              title="Download Brochure"
              subject="VMF EU Download Brochure Request"
            />
          )}
        />
        <Route
          path="/request-agenda"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="AGENDA"
              title="Request Agenda"
              subject="VMF EU Agenda Request"
            />
          )}
        />
        <Route
          path="/resources"
          exact
          render={(props) => (
            <Resource
              {...props}
              type="RESOURCE"
              title="The 2020 State of Martech Survey"
              subject="VMF EU resource Request"
            />
          )}
        />
        <Route
          path="/request-report"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="REPORT"
              title="Download 2022 Event Report"
              subject="VMF EU Download Report Request"
            />
          )}
        />
        <Route
          path="/sponsor"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="SPONSOR"
              title="Sponsor VMF"
              subject="VMF EU Sponsor Request"
            />
          )}
        />
        <Route path="/key-themes" exact render={(props) => <KeyThemes {...props} />} />
        <Route path="/partners" exact render={(props) => <Sponsors {...props} />} />
        <Route path="/sponsors/:sponsor" exact render={(props) => <SponsorSingle {...props} />} />
        <Route path="/agenda" exact render={(props) => <Agenda {...props} />} />
        <Route path="/privacy-policy" exact render={(props) => <PrivacyPolicy {...props} />} />
        <Route path="/terms" exact render={(props) => <Termsconditons {...props} />} />
        <Route path="/speakers" exact render={(props) => <Speakers {...props} />} />
        <Route path="/about" exact render={(props) => <About {...props} />} />
        <Route path="/on-demand" exact render={(props) => <OnDemand {...props} />} />
        <Route path="/past-speakers" exact render={(props) => <PastSpeakers {...props} />} />
        <Route path="/past-sponsors" exact render={(props) => <PastSponsors {...props} />} />
        <Route path="/thank-you/:id" exact render={(props) => <ThankYouRegister {...props} />} />

        <Route path="/news/:news" exact render={(props) => <NewsSingle {...props} />} />
        <Route path="/colin-shaw" exact render={(props) => <Colin {...props} />} />
        <Route path="/payment" exact render={(props) => <PaymentProcess {...props} />} />
        <Route path="/payment-redirect" exact render={(props) => <PaymentRedirect {...props} />} />
        <Route
          path="/payment-redirect/:id"
          exact
          render={(props) => <PaymentRedirectStatus {...props} />}
        />
        <Route path="/pricing-options" exact render={(props) => <PriceCardPage {...props} />} />
        <Route path="/speaker-insights" exact render={(props) => <Insights {...props} />} />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
