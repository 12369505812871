import React from "react";
import { Container, Row, Col, Button, Card } from "reactstrap";
import CountUp from "react-countup";

import "react-circular-progressbar/dist/styles.css";

function TextGrid() {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  return (
    <>
      <div
        className="section pb-0"
        style={{
          paddingTop: !size && "2rem",
          paddingBottom: !size && "0rem",
          backgroundColor: "transparent",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col
              md={8}
              xs={12}
              style={{
                backgroundColor: "#DDD1EF",
                // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                backgroundSize: "cover",
                backgroundRepeat: "none",
                border: "2px solid #000",
              }}
            >
              <div
                className="text-dark"
                style={{ padding: size ? "3rem 0rem 3rem 3rem" : "3rem 1rem 4rem 1rem" }}
              >
                <h2 className="text-700 text-center text-dark mb-2 text-uppercase" text-center>
                  VMF THROUGH THE YEARS
                </h2>
                <br />
                <p className="text-400">
                  Since 2018, Vibe Martech Fest (VMF) has advanced the adoption of Martech by
                  education, enlightening, and inspiring the CMOs. It is the Middle East’s first,
                  largest and most credible marketing technology summit for senior marketing and IT
                  professionals.
                  <br />
                  <br />
                  <b>
                    As martech continuously becomes more progressive, how will it help fast track
                    your business growth?
                  </b>
                  <br />
                  <br />
                  Now in its 11th Edition, the Vibe Martech Fest Series has landed in London for its
                  first European Edition! An enticing agenda filled with case studies, panel
                  discussions, fireside chats, and keynotes on the latest MarTech trends and topics,
                  giving our 200+ attendees plenty to discuss.
                </p>
                <br />
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: "-2rem" }}>
          <Row className="justify-content-center mb-5">
            <Col
              md={9}
              xs={12}
              style={{
                backgroundColor: "#E0FA88",
                // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                backgroundSize: "cover",
                backgroundRepeat: "none",
                paddingBottom: "2rem",
                border: "2px solid #000",
              }}
            >
              <h2 className="text-700 text-center text-dark mb-5 text-uppercase" text-center>
                What to expect at VMF Europe Edition
              </h2>
              <div className={`container-fluid row ${size ? "row-cols-4" : "row-cols-2"}`}>
                {content.map((c, i) => (
                  <Col
                    // lg={3}
                    // xs={6}
                    className={`text-center ${i !== 0 && size && "border-dark border-left"}`}
                  >
                    <h1 className="text-700  mt-0" style={{ color: "#274FE4" }}>
                      <CountUp end={c.value} />+
                    </h1>
                    <h5 className="text-400  mt-0" style={{ color: "#274FE4" }}>
                      {c.title}
                    </h5>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  { value: 200, title: "Marketing and Technology Leaders in Attendance" },
  { value: 20, title: "Trending Martech and Solution Providers" },
  { value: 30, title: "Expert Speakers" },
  { value: 20, title: "Sessions on Key Marketing Strategies " },
];
export default TextGrid;
