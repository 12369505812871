import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#f8f8f8",
        }}
        className="section section-with-space"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col md={12} className="align-self-center">
              <p className="text-400" style={{ fontSize: "18px" }}>
                Since 2018, Vibe Martech Fest (VMF) has advanced the adoption of Martech by
                education, enlightening, and inspiring the CMOs. It is the Middle East’s first,
                largest and most credible marketing technology summit for senior marketing and IT
                professionals.
                <br />
                <br />
                Now, on its 11th Edition, Vibe Martech Fest has landed in London for its first
                European Edition! An enticing agenda filled with case studies, panel discussions,
                fireside chats, and keynotes on the latest MarTech trends and topics, giving our
                200+ attendees plenty to discuss.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12}>
              <h2
                className="text-700 m-0 mt-5 text-uppercase text-center"
                style={{
                  color: "#000",
                }}
              >
                What to expect at VMF Europe Edition
              </h2>
            </Col>
            <Col lg={12}>
              <Row className="mt-3 justify-content-center" style={{ marginTop: "5rem" }}>
                {content.map((c, i) => (
                  <Col lg={3} xs={6} style={{ padding: "1rem" }} key={i}>
                    <div style={{ maxWidth: "175px" }} className="mx-auto mb-3">
                      <h1 className="text-center text-700" style={{ color: "#264FEB" }}>
                        {c.value}+
                      </h1>
                    </div>
                    <h5 className="text-400 text-center text-dark text-uppercase">{c.title}</h5>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="text-center">
              <Button
                href="/pricing-options"
                className="rounded-0 text-700 px-4  mt-5"
                style={{
                  background: "#fff",
                  border: "2px solid #000",
                }}
                size="lg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    fontSize: "20px",
                    color: "#000",
                  }}
                >
                  REGISTER
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  { value: 200, title: "Marketing and Technology Leaders in Attendance" },
  { value: 20, title: "Trending Martech and Solution Providers" },
  { value: 30, title: "Expert Speakers" },
  { value: 20, title: "Sessions on Key Marketing Strategies " },
];
