import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import "react-circular-progressbar/dist/styles.css";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const mdSize =
    document.body.clientWidth >= 1324 || document.documentElement.clientWidth >= 1324
      ? true
      : false;

  return (
    <>
      <div
        style={{
          backgroundColor: "#8CE4CF",
        }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={6} className="align-self-top">
              <h2
                className="text-700 m-0 mb-5 text-uppercase text-center"
                style={{
                  color: "#000",
                }}
              >
                Building a Future-ready Martech Stack
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} className="align-self-center">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe
                  src="https://www.youtube.com/embed/4beAEVAE5Uc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col md={8} className="align-self-top">
              <p className="text-400 text-dark">
                Vibe Martech Fest powered by Martechvibe, the Middle East region’s only and
                the world’s fastest-growing media platform dedicated to MarTech & AdTech. It is the
                only event which presents cutting-edge Martech solutions with a practical approach.
                <br />
                <br />
                Thought leaders and experts like Scott Brinker, Sir Martin Sorrell, Brian Solis,
                Fernando Machado, David Raab, Rob Bloom, Steve Lok and Neil Patel have spoken at
                previous editions.
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        style={{ background: "#E0FA88" }}
        className="section"
        data-parallax={true}
        ref={pageHeader}
      >
        {/* {size && (
          <div>
            <img
              src={require('assets/images/Layer 44.png')}
              alt="awards"
              width="250px"
              style={{
                position: 'absolute',
                top: '-192px',
                left: mdSize ? '2.5vw' : '1.5vw',
              }}
              className="mx-auto mb-4"
            />
          </div>
        )} */}
        <Container>
          <Row className="justify-content-center">
            <Col md={4} className="text-center">
              <img
                src={require("assets/images/psr.png")}
                alt="awards"
                width="100%"
                // style={{ maxWidth: '500px' }}
                className="mx-auto mb-4"
                style={{ border: "15px solid #264EE3" }}
              />
              <br />
              <Button
                href="/request-report"
                className="rounded-0 navbar-item-custom px-4"
                size="lg"
                //target="_blank"
                style={{ background: "#264EE3", border: "2px solid #000" }}
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    fontWeight: 600,
                    color: "#fff",
                  }}
                >
                  DOWNLOAD POST-SHOW REPORT
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <div
        style={{
          backgroundColor: "#fff",
        }}
        data-parallax={true}
        className=""
        ref={pageHeader}
      >
        <Container className="pb-5">
          <Row className="justify-content-center">
            <Col lg={12}></Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={12} className="text-dark text-center">
              <h2
                className="text-700 m-0 my-5 text-uppercase"
                style={{
                  color: "#254BEA",
                }}
              >
                Gain insights on key technologies
              </h2>
            </Col>
            <Col lg={12}>
              <Row className="mt-3 justify-content-center">
                {content1.map((c, i) => (
                  <Col lg={4} xs={6} className="pb-2 text-center" key={i}>
                    <img
                      src={require(`assets/keytopics/${c.image}`)}
                      alt="keytopics"
                      width="100%"
                      style={{ maxWidth: "180px" }}
                    />
                    <h5 className="text-400 mt-2 mb-4 text-center text-dark">{c.text}</h5>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  {
    value: 1700,
    title: "Marketing and Technology  Leaders in Attendance",
    image: "6.png",
  },
  { value: 50, title: "Trending Martech Solution Providers", image: "7.png" },
  { value: 150, title: "Expert Speakers", image: "8.png" },
  { value: 180, title: "Sessions on Key Marketing Strategies", image: "9.png" },
];

const content1 = [
  { image: "1.png", text: "Customer Data Platform" },
  { image: "2.png", text: "Marketing Analytics" },
  { image: "3.png", text: "Social Media Listening & Analytics" },
  { image: "4.png", text: "Data Unification Visualization and Representation" },
  { image: "5.png", text: "Marketing Automation & CRM" },
  { image: "6.png", text: "AI, ML, and Personalisation" },
];
