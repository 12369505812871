import { apiURL } from "constant";
import { token } from "constant";
import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const DataContext = createContext({});

export default function DataContainer({ children }) {
  const [data, setData] = useState({
    agenda: [],
    speakers: [],
  });

  useEffect(() => {
    fetch(`${apiURL}/getEventData`, {
      method: "get",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData({
          speakers: data.speakers,
          agenda: data.agenda,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return <DataContext.Provider value={{ ...data }}>{children}</DataContext.Provider>;
}
