import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Input, Form, Alert, Spinner, Label } from "reactstrap";
import { Redirect } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import countries from "./countries";
import { apiURL } from "constant";
import { token } from "constant";

function Register({ type, subject }) {
  const urlParams = new URLSearchParams(window.location.search);
  const utm_source = urlParams.get("utm_source");
  const [details, setDetails] = React.useState({
    type,
    optIn: true,
    privacyPolicy: true,
    utmSource: utm_source,
  });
  const [errorObj, setErrorObj] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onChangeDetails = (e) => {
    setErrorObj({});
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const onChangeDetailsUdf = (e) => {
    setDetails({ ...details, udf: { ...details.udf, [e.target.name]: e.target.value } });
  };

  const checkboxChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.checked });
  };
  const [other, setOther] = React.useState(false);
  const onChangeIndustry = (e) => {
    const val = e.target.value;
    if (val === "Other") {
      setOther(true);
    } else {
      setOther(false);
      setDetails({ ...details, industry: e.target.value });
    }
  };

  const validateInputs = () => {
    const reg =
      /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;

    if (!details.firstName) {
      setErrorObj({ ...errorObj, first_name: "Name is required." });
      return false;
    }
    if (!details.lastName) {
      setErrorObj({ ...errorObj, last_name: "Name is required." });
      return false;
    }
    if (!details.email || !reg.test(details.email.toLowerCase())) {
      setErrorObj({ ...errorObj, email: "Enter Valid Email Address." });
      return false;
    }
    if (!details.phone) {
      setErrorObj({ ...errorObj, phone: "Phone is required." });
      return false;
    }
    if (!details.title) {
      setErrorObj({ ...errorObj, job: "Job Title is required." });
      return false;
    }
    if (!details.company) {
      setErrorObj({ ...errorObj, company: "Company is required." });
      return false;
    }
    if (!details.country) {
      setErrorObj({ ...errorObj, email: "Select a country from the dropdown." });
      return false;
    }
    if (!details.udf.industry) {
      setErrorObj({ ...errorObj, email: "Industry is required" });
      return false;
    }

    return true;
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    setSuccess(false);

    const validateInp = validateInputs();
    if (!validateInp) {
      setLoading(false);
      setError("Please correct one or more fields above.");
      return null;
    }

    fetch(`${apiURL}/setDelegateData`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(details),
    })
      .then((res) => {
        console.log(res);
        if (res.ok) return res;
        else throw new Error(res);
      })
      .then(() => {
        setLoading(false);
        if (type === "AGENDA") {
          window.location.href =
            "https://drive.google.com/uc?id=1s1MPi2IsvnkFkCQU6Mi4i8ggQ6kxnME_&export=download";
          setSuccess(
            "https://drive.google.com/uc?id=1s1MPi2IsvnkFkCQU6Mi4i8ggQ6kxnME_&export=download"
          );
        } else if (type === "REPORT") {
          window.location.href =
            "https://drive.google.com/uc?id=1gudt7WaccHwLUXdEbUtsrUXJeeV8NqR9&export=download";
          setSuccess(
            "https://drive.google.com/uc?id=1gudt7WaccHwLUXdEbUtsrUXJeeV8NqR9&export=download"
          );
        } else {
          setRedirect(true);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Something went wrong please try again");
      });
  };

  let inputs = content;
  if (redirect) {
    return <Redirect to={`/thank-you/${type}`} />;
  }

  const ErrorComp = ({ error }) => (
    <span
      style={{
        background: "#FF0000",
        color: "#fff",
        padding: "2px",
        marginTop: "2px",
      }}
    >
      {error}
    </span>
  );

  return (
    <div
      style={{
        backgroundColor: "#134fed",

        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
      }}
      className="section py-3"
    >
      <Container>
        <Row>
          {/* <Col lg={6}>
            <SideContentRegister type={type} />
          </Col> */}
          <Col className="mx-auto mb-5 py-5" lg={6}>
            <Container className="my-auto text-400 text-white">
              <Form onSubmit={onSubmitForm} className="register-form">
                <Row>
                  {inputs.map((i) =>
                    i.name !== "phone" ? (
                      <Col lg={6} className="my-2" key={i.name}>
                        <label>{i.placeholder}*</label>
                        <Input
                          placeholder={i.placeholder}
                          type={i.type}
                          name={i.name}
                          onChange={onChangeDetails}
                          required
                        />
                        {errorObj[i.name] && <ErrorComp error={errorObj[i.name]} />}
                      </Col>
                    ) : (
                      <Col lg={6} className="my-2">
                        <label>Phone*</label>
                        <PhoneInput
                          country={"sa"}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          inputClass="w-100"
                          dropdownClass="text-dark"
                          value={details.phone}
                          onChange={(phone) => {
                            setDetails({ ...details, phone });
                          }}
                        />
                        {errorObj.phone && <ErrorComp error={errorObj.phone} />}
                      </Col>
                    )
                  )}
                  <Col lg={6} className="my-2">
                    <label>Country *</label>
                    <Input
                      placeholder="Select"
                      type="select"
                      name="country"
                      onChange={onChangeDetails}
                      required
                    >
                      <option value="">Select</option>
                      {countries.map((c) => (
                        <option>{c}</option>
                      ))}
                    </Input>
                    {errorObj.country && <ErrorComp error={errorObj.country} />}
                  </Col>
                  {type === "REGISTER" && (
                    <Col lg={6} className="my-2">
                      <label>Promo Code</label>
                      <Input
                        type="text"
                        name="promo"
                        id="promo"
                        onChange={onChangeDetails}
                        placeholder="Promo Code"
                      />
                    </Col>
                  )}
                  <Col lg={6} className="my-2">
                    <label>Industry*</label>
                    <Input
                      placeholder="Select"
                      type="select"
                      name="industry"
                      onChange={onChangeDetailsUdf}
                      required
                    >
                      <option value="">Select</option>
                      <option>Airline</option>
                      <option>Retail</option>
                      <option>Real Estate</option>
                      <option>Education</option>
                      <option>Telecommunication</option>
                      <option>Banking/Finance</option>
                      <option>Tourism & Hospitality</option>
                      <option>Consumer Electronics</option>
                      <option>Media</option>
                      <option>Entertainment</option>
                      <option>Logistic & Supply Chain</option>
                      <option>Other</option>
                    </Input>
                    {errorObj.industry && <ErrorComp error={errorObj.industry} />}
                  </Col>
                  {other && (
                    <Col lg={12} className="my-2">
                      <Input
                        type="text"
                        name="industry"
                        id="industry"
                        onChange={onChangeDetailsUdf}
                        placeholder="Industry"
                        required
                      />
                      {errorObj.industry && <ErrorComp error={errorObj.industry} />}
                    </Col>
                  )}
                  {type === "SPONSOR" && (
                    <Col lg={12} className="my-2">
                      <label>Which edition are you interested to participate in?</label>
                      <Input
                        placeholder="Select"
                        type="select"
                        name="location"
                        onChange={onChangeDetailsUdf}
                        required
                      >
                        <option value="">Select</option>
                        <option>Saudi Arabia</option>
                        <option>London </option>
                        <option>South Africa </option>
                        <option>UAE</option>
                        <option>Kenya</option>
                      </Input>
                    </Col>
                  )}
                  {type === "SPONSOR" && (
                    <Col lg={12} className="my-2">
                      <label>Are you interested in hosting C-Suite Boardrooms? </label>
                      <Input
                        placeholder="Select"
                        type="select"
                        name="boardroom"
                        onChange={onChangeDetailsUdf}
                        required
                      >
                        <option value="">Select</option>
                        <option>Yes</option>
                        <option>No</option>
                      </Input>
                    </Col>
                  )}

                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="marketing_info"
                          onChange={checkboxChange}
                          checked={details.marketing_info}
                        />{" "}
                        Yes, by signing up, I agree to receive Vibe Projects publications (
                        <a
                          href="https://martechvibe.com/"
                          target="_blank"
                          rel="noopener"
                          className="text-600 text-info"
                          data-saferedirecturl="https://www.google.com/url?q=https://martechvibe.com/&amp;source=gmail&amp;ust=1654271285742000&amp;usg=AOvVaw37pUDeWmlBaDjM9xJQXzhS"
                        >
                          Martechvibe
                        </a>
                        {/* &nbsp;and&nbsp;
                        <a
                          href="https://www.fastcompanyme.com/"
                          target="_blank"
                          rel="noopener"
                          className="header-text text-info"
                          data-saferedirecturl="https://www.google.com/url?q=https://www.fastcompanyme.com/&amp;source=gmail&amp;ust=1654271285742000&amp;usg=AOvVaw24QssHQr0vpNJQ84PROp2_"
                        >
                          Fast Company Middle East
                        </a> */}
                        ) newsletters and other marketing communications through email.&nbsp;
                      </Label>
                    </div>
                  </Col>
                  <Col lg={12} className="my-2">
                    <div className="ml-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          name="privacyPolicy"
                          onChange={checkboxChange}
                          checked={details.privacyPolicy}
                        />{" "}
                        Yes, by signing up, I agree to have my contact information, including email,
                        passed on to the sponsors of this event for the purpose of following up on
                        your interests. Please read our{" "}
                        <a href="/privacy-policy" className="text-info text-600">
                          privacy policy,{" "}
                        </a>
                        <a href="/terms" className="text-info text-600">
                          terms and conditions
                        </a>
                      </Label>
                    </div>
                  </Col>
                </Row>

                {loading ? (
                  <span>
                    <Spinner className="mt-3" color="warning" size="sm" />
                  </span>
                ) : (
                  <Button
                    type="submit"
                    disabled={loading}
                    className="text-600 px-4 w-100"
                    style={{ background: "white", border: "2px solid #000" }}
                    size="lg"
                  >
                    <span
                      style={{
                        fontWeight: 700,
                        color: "#000",
                      }}
                    >
                      SUBMIT
                    </span>
                  </Button>
                )}
              </Form>
              {type === "REGISTER" && (
                <Row>
                  <Col lg={12}>
                    <p className="text-700 mt-3">
                      Please note: Filling out the form does not guarantee admission to the event.
                      As a standard protocol, the Organising Team will evaluate the registration
                      based on the attendee eligibility criteria. The Organising Team will get in
                      touch with you for further details should your registration be accepted.{" "}
                    </p>
                  </Col>
                </Row>
              )}
            </Container>
            <CreateAlert success={success} error={error} type={type} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function CreateAlert({ success, error, type }) {
  const color = success ? "success" : "danger";

  if (!success && !error) {
    return "";
  } else {
    return (
      <Alert
        color={color}
        isOpen={true}
        fade={true}
        className="text-center"
        style={{ marginTop: "1rem" }}
      >
        {error ? (
          error
        ) : (
          <>
            Thank you. The {type === "AGENDA" ? "agenda" : "report"} will download shortly.{" "}
            <a href={success} target="_blank" rel="noopener noreferrer" className="text-white">
              Click here
            </a>{" "}
            if your download does not begin.
          </>
        )}
      </Alert>
    );
  }
}

export default Register;

const content = [
  { id: 1, name: "firstName", type: "text", placeholder: "First Name" },
  { id: 1, name: "lastName", type: "text", placeholder: "Last Name" },
  { id: 3, name: "email", type: "text", placeholder: "Business Email" },
  { id: 4, name: "phone", type: "text", placeholder: "Mobile" },
  { id: 5, name: "company", type: "text", placeholder: "Company" },
  { id: 6, name: "title", type: "text", placeholder: "Job Title" },
];
