import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";

function Sponsor({ sponsors }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  return (
    <>
      <div
        style={{
          backgroundColor: "#f8f8f8",
        }}
        className='section pt-0'
        data-parallax={true}
      >
        <Container>
          {/* <Row>
            <Col lg={12}>
              <h1 className="text-900 text-center mb-4"></h1>
            </Col>
          </Row> */}

          <Row>
            {sponsors.map((s) => (
              <Col
                lg={s.grid || 3}
                xs={s.smallGrid || 6}
                className={"ml-auto mr-auto pb-3"}
              >
                <a href={`/sponsors/${s.pageLink}`}>
                  <Card style={{ borderRadius: size ? "20px" : "10px" }}>
                    <Container fluid>
                      <Row>
                        <Col lg={12}>
                          <img
                            src={require(`assets/sponsors/${s.image}`)}
                            alt={s.name}
                            width='100%'
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>

          <Row>
            <Col lg={12} className={"text-center"}>
              <Button
                href='/sponsor-cxnxt'
                className='navbar-item-custom text-400 text-white px-4 rounded-0 mb-3'
                color='danger'
                size='lg'
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className='header-text'
                >
                  SPONSOR GLOBAL EDITIONS
                </span>
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
