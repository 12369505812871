// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="section"
        style={{
          backgroundColor: "transparent",
          backgroundImage: size
            ? `url(${require("assets/images/BG.png")})`
            : `url(${require("assets/images/BG1.png")})`,
          backgroundSize: size ? "100%" : "cover",
          backgroundRepeat: "no-repeat",
          marginTop: size ? "7.8rem" : "0rem",
          paddingBottom: size ? "8.2rem" : "10rem",
        }}
        data-parallax={true}
      >
        {/* <div className="overlay-primary"></div> */}
        {size ? (
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col md={6} xs={12} className="text-left">
                <h1
                  className="header-text text-white m-0 text-center"
                  style={{ fontSize: "5vw", lineHeight: "5vw", letterSpacing: "8px" }}
                >
                  <b>FUTURE-READY MARTECH</b>
                </h1>

                <h3
                  className="sub-header-text text-white pl-1 text-center"
                  style={{ fontSize: "1.5vw" }}
                >
                  <b>
                    11th Edition <br />
                    {/* 26th - 27th April 2023 */}
                    <br />
                    London, United Kingdom
                    <br />
                  </b>
                </h3>

                <Container fluid className="pt-3">
                  <Row>
                    <Col lg={12} className="pb-3 px-0 text-center">
                      <Button
                        href="/pricing-options"
                        className="navbar-item-custom text-400 text-dark rounded-0 "
                        style={{
                          background: "#fff",
                          border: "2px solid #000",
                        }}
                        size="lg"
                      >
                        <span
                          style={{
                            color: "#000",
                            textTransform: "none",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          className="text-600"
                        >
                          REGISTER <i class="fa fa-arrow-right" />
                        </span>
                      </Button>
                    </Col>
                    <Col lg={12} className="px-0 text-center">
                      <Button
                        href="/sponsor"
                        className="navbar-item-custom text-400 text-dark rounded-0"
                        style={{
                          background: "#fff",
                          border: "2px solid #000",
                        }}
                        size="lg"
                      >
                        <span
                          style={{
                            color: "#000",
                            textTransform: "none",
                            fontWeight: 700,
                            fontSize: "16px",
                          }}
                          className="text-600"
                        >
                          BECOME A SPONSOR
                          <i class="fa fa-arrow-right" />
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        ) : (
          <>
            <div className="d-block w-100" style={{ paddingTop: "5rem" }}>
              <Container className="">
                <Row>
                  <Col lg={12} className="text-center mt-0">
                    <h1
                      className="text-700 text-white m-0"
                      style={{ fontSize: "12vw", lineHeight: "15vw", letterSpacing: "5px" }}
                    >
                      <b>FUTURE-READY MARTECH</b>
                    </h1>
                    <h3 className="text-700 text-white mb-2" style={{ fontSize: "5vw" }}>
                      <b>
                        11th Edition <br />
                        {/* 26th - 27th April 2023 */}
                        <br />
                        London, United Kingdom
                        <br />
                      </b>
                    </h3>
                    <Button
                      href="/pricing-options"
                      className="navbar-item-custom text-400 text-white px-4 rounded-0 mt-3 "
                      style={{
                        background: "white",
                        border: "2px solid #000",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        REGISTER NOW <i class="fa fa-arrow-right"></i>
                      </span>
                    </Button>
                    <br />
                    <Button
                      href="/sponsor"
                      className="navbar-item-custom text-400 text-white px-4 rounded-0 mt-3"
                      style={{
                        background: "white",
                        border: "2px solid #000",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        BECOME A SPONSOR <i class="fa fa-arrow-right" />
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default LandingPageHeader;
