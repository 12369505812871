/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Register from "./Register";
import OrderInfo from "./OrderInfo";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import NavbarTop from "components/NavbarTop.js";
import Footer from "components/Footer.js";
import LoadingOverlay from "react-loading-overlay";
import { GoSell } from "@tap-payments/gosell";
import config, { pricingConfig, promoMap } from "./config";

function Payment(props) {
  const { location } = props;
  const searchParams = new URLSearchParams(location.search);
  const option = searchParams.get("option") || "delegate";
  const lead = searchParams.get("lead");

  const [discount, setDiscount] = useState({
    type: "P",
    value: "0%",
  });
  const [customer, setCustomer] = useState({
    type: "REGISTER",
    optIn: true,
    privacyPolicy: true,
    utmSource: lead,
  });
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [promoApplied, setPromoApplied] = React.useState(false);

  const [price, setPrice] = React.useState(
    pricingConfig[option].price * pricingConfig[option].delegates
  );

  const onSubmitForm = () => {
    GoSell.openLightBox();
  };
  const callbackFunc = (response) => {
    console.log(response);
  };

  const onClose = () => {
    setLoading(false);
  };

  const applyCoupon = () => {
    const promoDiscount = promoMap[customer.promo];
    console.log(price);
    if (promoDiscount) {
      const discount = price * (promoDiscount / 100);
      setPrice(price - discount);
      setDiscount({
        type: "P",
        value: "10%",
      });
      setPromoApplied(true);
      setError("");
    } else {
      setError("Invalid Promo Code Applied");
    }
  };

  const removeCoupon = () => {
    setCustomer({ ...customer, promo: undefined });
    setPrice(pricingConfig[option].price * customer.delegateCount);
    setPromoApplied(false);
  };

  const onChangePromo = (e) => {
    setCustomer({ ...customer, promo: e.target.value });
    setPromoApplied(false);
  };
  return (
    <>
      <NavbarTop />
      <Navbar />
      <ProfilePageHeader title={"BOOK NOW"} bgColor="#fff" />
      <LoadingOverlay active={loading} spinner text="Loading please do not refresh this page.">
        <div
          className="section py-3"
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Container className="py-5">
            <Row className="justify-content-center">
              <OrderInfo
                price={price}
                details={pricingConfig[option]}
                applyCoupon={applyCoupon}
                customer={customer}
                setCustomer={setCustomer}
                promoApplied={promoApplied}
                setPromoApplied={setPromoApplied}
                onChangePromo={onChangePromo}
                error={error}
                success={success}
                removeCoupon={removeCoupon}
              />
              <Col lg={6}>
                <Register
                  customer={customer}
                  setCustomer={setCustomer}
                  onSubmitForm={onSubmitForm}
                  loading={loading}
                  setLoading={setLoading}
                  setError={setError}
                  setSuccess={setSuccess}
                  setPrice={setPrice}
                  details={pricingConfig[option]}
                  setPromoApplied={setPromoApplied}
                  option={option}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </LoadingOverlay>
      <GoSell
        {...config({
          id: Date.now(),
          meta: customer,
          pricingOption: {
            price,
            details: pricingConfig[option].description,
          },
          callback: callbackFunc,
          onClose: onClose,
          discount,
        })}
      />
      <Footer />
    </>
  );
}

export default Payment;
