import React from "react";
import { Row, Container, Col } from "reactstrap";

function DemoFooter() {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;
  return (
    <footer
      className="footer py-5"
      style={{
        // backgroundImage: `url(${require("assets/images/bgfooter.png")})`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundAttachment: "initial",
        // backgroundPosition: size ? "85%" : "top",
        background: "#000",
      }}
    >
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col lg={6} className="mb-4 text-center">
            <img
              src={require("assets/images/logo/Vibe logo white.png")}
              alt="vibe"
              width="100%"
              style={{ maxWidth: "200px" }}
            />
            <p className="text-400 text-white mt-4">POWERED BY</p>
            <a href="https://martechvibe.com">
              <img
                src={require("assets/images/logo/martechvibelogo2.png")}
                alt="vibe"
                width="100%"
                style={{ maxWidth: "200px" }}
              />
            </a>
          </Col>
          <Col lg={1} />
          <Col lg={4} className={size ? "text-left" : "text-center"}>
            {links.map((l) => (
              <h5 className="mt-0" key={l.title}>
                <a href={l.link} style={{ color: "#fff" }} className=" text-600">
                  {l.title}
                </a>
              </h5>
            ))}

            <p className="text-600 mt-3" style={{ color: "#0fd895" }}>
              STAY CONNECTED
            </p>

            {social.map((s) => (
              <a
                key={s.name}
                className={`btn-primary text-900 pt-4 px-4`}
                style={{
                  color: "#fff",
                  backgroundColor: "transparent",
                }}
                href={s.link}
              >
                <i className={`fa fa-${s.name} text-white`} style={{ fontSize: "2rem" }} />
              </a>
            ))}
          </Col>
          <Col lg={12} className="mt-5">
            <p className="text-600 text-white text-center">
              For sponsorship enquires contact:{" "}
              <a
                href="mailto:marketing@martechvibe.com"
                className="text-600"
                style={{ color: "#0fd895" }}
              >
                marketing@martechvibe.com
              </a>{" "}
              <br />
            </p>
            <p className="text-400 text-white text-center">
              © 2022 Vibe Projects LLC All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/martechvibe/",
  },
  {
    name: "twitter",
    link: "https://twitter.com/martechvibe",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/company/14603724/admin/",
  },
];

const links = [
  { title: "HOME", link: "/#" },
  { title: "OVERVIEW", link: "/about" },
  { title: "AGENDA", link: "/#agenda" },
  { title: "SPEAKERS", link: "/#speakers" },
  // { title: "PARTNERS", link: "/#partners" },
  // { title: "NEWS", link: "/#news" },
];
