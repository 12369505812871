import React from "react";

export default ({ id, meta, pricingOption, callback, onClose, discount }) => {
  const { udf, ...met } = meta;
  met.udf = JSON.stringify(udf);
  return {
    gateway: {
      // publicKey: "pk_live_gpZfDVKBiu0we3vGJmqELkcr",
      publicKey: "pk_test_G4lgbU352iErtCkLoDHJTZeY",
      language: "en",
      contactInfo: true,
      supportedCurrencies: "all",
      supportedPaymentMethods: "all",
      saveCardOption: true,
      customerCards: true,
      notifications: "standard",
      backgroundImg: {
        url: "imgURL",
        opacity: "0.5",
      },
      callback,
      onClose,
      labels: {
        cardNumber: "Card Number",
        expirationDate: "MM/YY",
        cvv: "CVV",
        cardHolder: "Name on Card",
        actionButton: "Pay",
      },
      style: {
        base: {
          color: "#535353",
          lineHeight: "18px",
          fontFamily: "sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "rgba(0, 0, 0, 0.75)",
            fontSize: "15px",
          },
        },
        invalid: {
          color: "red",
          iconColor: "#fa755a ",
        },
      },
    },
    customer: {
      first_name: meta.firstName,
      middle_name: " ",
      last_name: meta.lastName,
      email: meta.email,
      phone: {
        country_code: meta.countryCode,
        number: meta.phone,
      },
    },
    order: {
      amount: pricingOption.price,
      currency: "USD",
      items: [
        {
          id: 1,
          name: pricingOption.details.description,
          description: pricingOption.details.description,
          quantity: "x1",
          amount_per_unit: "US00.000",
          total_amount: "US000.000",
          discount,
        },
      ],
      shipping: null,
      taxes: null,
    },
    transaction: {
      mode: "charge",
      charge: {
        saveCard: false,
        threeDSecure: true,
        description: "Test Description",
        statement_descriptor: "Sample",
        reference: {
          transaction: `txn_${id}`,
          order: `ord_${id}`,
        },
        metadata: met,
        receipt: {
          email: true,
          sms: true,
        },
        redirect: "https://eu.vibemartechfest.com/payment-redirect",
        post: null,
      },
    },
  };
};

export const pricingConfig = {
  delegate: {
    price: (599 * 1.025).toFixed(2),
    delegates: 1,
    description: "Individual Delegate Pass",
    body: (
      <ul>
        <li>Full Day Passes (1+1) to the Conference</li>
        <li>Access to the Post Event Speaker Presentations</li>
        <li>Complimentary 1 year Membership to the World Martech Council</li>
        <li>Access to Networking Session</li>
        <li>Lunch & Refreshments</li>
      </ul>
    ),
  },
  group: {
    price: (449.75 * 1.025).toFixed(2),
    delegates: 4,
    description: "Group Booking (4x Delegates)",
    body: (
      <ul>
        <li>Two Full-Day Access Pass to the Conference for Up to 4 Delegates </li>
        <li>Access to Post Event Speaker Presentations </li>
        <li>Complimentary 1 year Membership to the World Martech Council </li>
        <li>Access to Networking Session</li>
        <li>Lunch & Refreshments</li>
      </ul>
    ),
  },
};

export const promoMap = {
  VMFEU10: 10,
};
