export const jury = [];
export const sponsorsnew = [];
export const speakers = [];
export const sponsors = [
  {
    name: "Sprinklr",
    pageLink: "Sprinklr",
    type: "PRESENTING SPONSOR",
    image: "sprinklr.png",
    text: [
      `Sprinklr is the world’s leading Customer Experience Management (CXM) platform. We
    help organizations listen to customers, learn from them and show them love across
    digital channels. Headquartered in New York City with 2,000+ employees globally,
    Sprinklr works with the world’s most valuable companies including L'Oréal, McDonald’s,
    Microsoft and more than 50% of the Fortune 100.`,
    ],
    link: "https://www.sprinklr.com/",
  },
  {
    name: "Salesforce",
    pageLink: "Salesforce",
    type: "STRATEGIC SPONSOR",
    image: "salesforce.jpg",
    text: [
      "Salesforce is the #1 CRM, bringing companies and customers together in the digital age. Founded in 1999, Salesforce enables companies of every size and industry to take advantage of powerful technologies—cloud, mobile, social, voice, and artificial intelligence—to connect to their customers in a whole new way. They are coming to us as their trusted advisor, and together we are transforming their businesses around the customer.",
      "Salesforce Customer 360 is our vision for how we help customers with their digital transformations. With Customer 360, all your teams — marketing, sales, service, commerce, IT, and analytics — have a single view of customer data on an integrated CRM platform powered by AI. Collaboration is easier, so employees can work together to build lasting, trusted relationships and deliver the intelligent, personalized experiences their customers expect.",
      "Salesforce is committed to a set of core values—trust, customer success, innovation, and equality of every human being. Salesforce is one of the leaders on Fortune’s Change the World list, and has been one of Fortune’s 100 Best Companies to Work For, for twelve years in a row.",
    ],
    link: "https://www.salesforce.com",
  },
  {
    name: "Tealium",
    pageLink: "Tealium",
    type: "GOLD SPONSOR",
    image: "TEA.png",
    text: [
      "Tealium revolutionizes today’s digital businesses with a universal approach to customer data orchestration – spanning web, mobile, offline, and IoT devices. With the power to unify customer data into a single source of truth, Tealium offers a turnkey integration ecosystem supporting over 1,200 client-side and server-side vendors and technologies. The Tealium Universal Data Hub encompasses tag management, API hub, customer data platform, and data management solutions that enable organizations to leverage real-time data to create richer, more personalized digital experiences across every team, technology, and customer touchpoint. More than 850 businesses worldwide trust Tealium to power their customer data strategies.",
    ],
    link: "https://tealium.com/",
  },
  {
    name: "ObservePoint",
    pageLink: "ObservePoint",
    type: "GOLD SPONSOR",
    image: "OP.png",
    text: [
      `Great digital experiences start with accurate analytics data. We help teams establish an automated process that validates marketing technologies and restores confidence in your data-driven decisions. ObservePoint's automated platform helps cross-departmental teams plan, deploy, QA, and validate their Martech and analytics strategy at scale, so they can achieve unparalleled data quality and gain complete confidence in their data.`,
      "<b>Why should you use ObservePoint?</b>",
      "Improve analytics testing efficiency by 83% <br />Increase digital analytics accuracy by 30% <br />Increase analytics team productivity by 75% <br />Scale testing frequency by more than 500%",
      "<b>Make better decisions with better data.</b> ObservePoint’s automated scanning tech will help you verify data was collected correctly so you can be confident in your marketing and analytics data.",
      "<b>Increase testing efficiency.</b> Instead of taking 25 hours to scan 1,000 pages, you could take 20-25 minutes to set up an automated, reusable ObservePoint audit that notifies you whenever an error occurs.",
      "<b>Ensure data protection and GDPR Compliance.</b> An ObservePoint scan can help you identify PII and any unauthorised or outdated technologies you need to remove from your site, protecting data from unauthorised use.",
    ],
    link: "https://www.observepoint.com/",
  },
  {
    name: "Branch",
    pageLink: "Branch",
    type: "GOLD SPONSOR",
    image: "branch.png",
    text: [
      `Branch
      provides the leading cross-platform linking and attribution platform, offering solutions that unify user experience and measurement across devices and channels. Branch powers mobile links and cross-platform measurement to more than 3 billion monthly users across
      the globe, and is a trusted cross-platform marketing, engagement, and measurement solution for over 50,000 apps — including Adobe, BuzzFeed, Yelp, OfferUp, and many more`,
    ],
    link: "https://branch.io/",
    grid: 4,
  },
  {
    name: "Netcore Cloud",
    pageLink: "Netcore",
    type: "GOLD SPONSOR",
    image: "netcore.png",
    text: [
      `Netcore is a full-stack, one-stop-shop for all your customer acquisition, engagement, monetisation, and retention efforts. Offering a host of cutting-edge martech solutions, we help brands across diverse industries craft and send highly data-driven and personalised customer experiences at scale. Powered by our AI Engine, Raman, coupled with our patented Machine Learning algorithms, we help accurately predict what your users are likely to purchase next to give your customers exactly what they want, shortening paths to final transaction!`,
    ],
    link: "https://netcorecloud.com",
    grid: 4,
  },
  {
    name: "Sitecore",
    pageLink: "Sitecore",
    type: "GOLD SPONSOR",
    image: "sitecore.png",
    text: [
      "Sitecore delivers a digital experience platform that empowers the world’s smartest brands to build lifelong relationships with their customers. A highly decorated industry leader, Sitecore is the only company bringing together content, commerce, and data into one connected platform that delivers millions of digital experiences every day. Leading companies including American Express, ASOS, Kimberly-Clark, L’Oréal and Volvo Cars rely on Sitecore to provide more engaging, personalized experiences for their customers.",
    ],
    link: "https://Sitecore.com/",
  },
  {
    name: "UNICA",
    pageLink: "UNICA",
    type: "GOLD SPONSOR",
    image: "unica.jpg",
    text: [
      "HCL Unica is a cloud native, fully integrated enterprise marketing automation platform that delivers precision marketing at scale and helps marketers achieve goals around revenue targets, increased wallet share, customer loyalty and more. A fully integrated platform provides marketing practitioners with end-to-end audience segmentation and omnichannel orchestration capabilities. Unica can be deployed through the Docker framework or natively in any cloud.",
    ],
    link: "https://www.hcltechsw.com/unica",
  },

  {
    name: "Braze",
    pageLink: "Braze",
    type: "SILVER SPONSOR",
    image: "braze.png",
    text: [
      "Braze is a leading comprehensive customer engagement platform that powers interactions between consumers and the brands they love. With Braze, global brands can ingest and process customer data in real-time, orchestrate and optimize contextually relevant, cross-channel marketing campaigns and continuously evolve their customer engagement strategies. ",
      `Braze has been recognized as one of Fortune's 2021 Best Workplaces in New York, Fortune's 2021 Best Workplace for Millennials, and 2021 UK Best Workplaces for Women by Great Place to Work. The company is headquartered in New York with offices in Austin, Berlin, Chicago, London, San Francisco, Singapore, and Tokyo.`,
    ],
    link: "https://www.braze.com/",
  },
  {
    name: "Verint",
    pageLink: "Verint",
    type: "SILVER SPONSOR",
    image: "verint.jpg",
    text: [
      `Verint® (Nasdaq: VRNT) helps the world’s most iconic brands build enduring customer relationships by connecting work, data and experiences across the enterprise. Verint allows organisations to adapt to the future of work, eliminate the inefficiencies created by organisational and data silos, and consistently deliver differentiated experiences at scale.
      The Verint Customer Engagement portfolio draws on the latest advancements in AI and analytics, an open cloud architecture, and the science of Customer Engagement™ to help organisations close the engagement capacity gap.
      With Verint, brands can finally unlock the potential of customer engagement across every area of the business to deliver consistently differentiated experiences to their customers and employees and do so at scale to realize tangible business results`,
    ],
    link: "Verint.com",
  },
  {
    name: "AppsFlyer",
    pageLink: "AppsFlyer",
    type: "SILVER SPONSOR",
    image: "appsflyer.jpg",
    text: [
      `AppsFlyer,
    the global attribution leader, empowers marketers to grow their business and innovate with a suite of comprehensive measurement and analytics solutions. Built around privacy by design, AppsFlyer takes a customer-centric approach to help 12,000+ brands and 8,000+
    technology partners make better business decisions every day.`,
    ],
    link: `http://www.appsflyer.com/`,
    grid: 4,
  },
  {
    name: "Webengage",
    pageLink: "Webengage",
    type: "SILVER SPONSOR",
    image: "webengage.png",
    text: [
      `WebEngage - The Retention OS for your digital consumer business<br/>WebEngage is a one-stop, multi-product operating system for retention marketing. The WebEngage Retention Operating System comprises a suite of integrated tools designed to execute data-backed engagement strategies that power user retention at scale. The three key products are CDP & Insights Platform, Omnichannel Campaign Orchestration Engine, & Web + App Personalization Suite. 
      <br/>
    It is built for modern consumer businesses to engage with evolved consumers across the globe. WebEngage is a powerful marketing automation platform that makes user engagement and retention simplified and highly effective for consumer tech enterprises and SMBs. Global brands like Unilever, Myntra, Souq, Extra Stores, PFI Mega Life, L'Occitane, Unacademy, and Intuit trust WebEngage with their user engagement needs.`,
    ],
    link: "https://webengage.com/",
    grid: 4,
  },
  {
    name: "SAS",
    pageLink: "SAS",
    type: "SILVER SPONSOR",
    image: "sas.jpg",
    text: [
      `SAS is the leader in analytics. Through innovative software and services, SAS empowers and inspires customers around the world to transform data into intelligence. SAS gives you THE POWER TO KNOW®.`,
    ],
    link: "www.sas.com/me",
    grid: 4,
  },
  {
    name: "Optimizely",
    pageLink: "Optimizely",
    type: "NETWORKING SPONSOR",
    image: "optimizely.jpg",
    text: [
      `At Optimizely, we're on a mission to help people unlock their digital potential. With our leading digital experience platform (DXP), we equip teams with the tools and insights they need to create and optimize in new and novel ways. Now, companies can operate with data-driven confidence to create hyper-personalized experiences. Building sophisticated solutions has never been simpler. Optimizely’s 900+ partners and 1100+ employees in offices around the globe are proud to help more than 9,000 brands, including Toyota, Santander, eBay, KLM and Mazda, enrich their customer lifetime value, increase revenue and grow their brands.`,
    ],
    link: "optimizely.com",
    grid: 4,
  },

  {
    name: "Cvent",
    pageLink: "Cvent",
    type: "EVENT TECHNOLOGY PARTNER",
    image: "cvent.jpg",
    text: [
      `The Cvent Event Cloud offers software solutions to event planners and marketers for online event registration, venue selection, event management and marketing, onsite solutions, and attendee engagement. Cvent's suite of products automate and simplify the planning process to maximise the impact of events. The Cvent Hospitality Cloud partners with hotels and venues to help them drive MICE and corporate travel business.`,
      `Hotels use the Cvent Hospitality Cloud's digital marketing tools and software solutions to win business through Cvent's sourcing platforms and to service their customers directly, efficiently and profitably – helping them grow and own their business.`,
      `Cvent solutions optimise the entire event management value chain and have enabled clients around the world to manage millions of meetings and events.`,
    ],
    link: "https://www.cvent.com/",
  },

  {
    name: "Zoho",
    pageLink: "Zoho",
    type: "ASSOCIATE SPONSOR",
    image: "zoho.png",
    text: [
      `With 45+ apps in nearly every major business category, including sales, marketing, customer support, accounting and back office operations, and an array of productivity and collaboration tools, Zoho Corporation is one of the world's most prolific software companies.
<br/>
    Zoho is privately held and profitable with more than 8,000 employees. Zoho is headquartered in Austin, Texas, with international headquarters in Chennai, India. Additional offices are in the United States, India, Japan, China, Singapore, Mexico, Australia, the Netherlands, and the United Arab Emirates.
    <br/>
    Zoho respects user privacy and does not have an ad-revenue model in any part of its business, including its free products. More than 60 million users around the world, across hundreds of thousands of companies, rely on Zoho every day to run their businesses, including Zoho itself.
   `,
    ],
    link: "https://www.zoho.com/",
    grid: 4,
  },
  {
    name: "Americaneagle.com",
    pageLink: "americaneagle",
    type: "EXHIBITOR",
    image: "americaneagle.png",
    text: [
      "Americaneagle.com is a full-service, global digital agency that has been dedicated to providing best-in-class web design, development and digital marketing services for over 25 years. With a team comprised of 650+ skilled professionals and subject matter experts, Americaneagle.com focuses on achieving measurable results and exceeding goals through SEO, Paid Search, Content Marketing strategies, and more. Thanks to our flexibility and expertise in a wide range of online services, we have the capabilities to handle businesses in any industry at any size, from mid-size to Fortune 500 companies, professional sports teams to government organizations, and large multi-channel retailers.",
    ],
    link: "https://www.americaneagle.com/",
    grid: 4,
  },
  {
    name: "PIXIS",
    pageLink: "PIXIS",
    type: "EXHIBITOR",
    image: "pixis.jpg",
    text: [
      `Pixis is a CA-based tech company that provides codeless AI infrastructure to enable customers to scale accurate data-driven marketing. The company’s codeless AI infrastructure currently comprises over four dozen proprietary AI models that are deployed across an ecosystem of products and plugins. Pixis is on a mission to provide marketers with robust plug-and-play AI products without them having to write a single line of code.  `,
      `Pixis’ distributed team of 300+ is focused on building the most transformative AI products to help customers get the most out of their marketing and demand generation efforts`,
    ],
    link: "https://pixis.ai/",
    grid: 4,
  },
  {
    name: "EPSILON",
    pageLink: "EPSILON",
    type: "EXHIBITOR",
    image: "epsilon.jpg",
    text: [
      `For decades, Epsilon has been helping marketers from the world’s top brands personalize experiences for millions of people with our cutting-edge technology, solutions and services. Epsilon’s best-in-class identity gives brands a clear, privacy-safe view of their customers, which they can use across our suite of digital media, messaging and loyalty solutions. We process 400+ billion consumer actions each day and hold many patents of proprietary technology, including real-time modeling languages and consumer privacy advancements. Epsilon has been consistently recognized as industry-leading by Forrester, Adweek and the MRC. Positioned at the core of Publicis Groupe, Epsilon is a global company with more than 8,000 employees around the world.`,
    ],
    link: "https://www.epsilon.com/apac",
    grid: 4,
  },
  {
    name: "Rebid",
    pageLink: "Rebid",
    type: "EXHIBITOR",
    image: "rebid.png",
    text: [
      `
      ReBid is the world&#39;s 1st Unified Marketing and Advertising Automation platform that leverages
both Adtech and Martech to drive ROAS for marketers and agencies, giving full control of digital
media spend, data and insights with an in-house option.<br/><br/>
ReBid has unified workflows, data harmonization and real-time automated reporting. This
translates into an immediate saving of 40% on resources and leads to incremental Returns on
Ad spends (ROAS) by over 30%.<br/><br/>
ReBid’s multiple partner integrations across ads, marketing, data management, ad serving,
analytics and more provide an unprecedented reach of 98% of global digital media audiences.
ReBid is an enterprise grade platform certified with highest global security and data compliance
certifications like SOC2, ISO 27001 and GDPR.
      `,
    ],
    link: "https://www.rebid.co/",
    grid: 4,
  },
  {
    name: "MoEngage",
    pageLink: "MoEngage",
    type: "EXHIBITOR",
    image: "moengage.jpg",
    text: [
      "MoEngage is an intelligent customer engagement platform built for the user-obsessed marketer. With AI-powered customer journey orchestration, personalization capabilities, and in-built analytics, MoEngage enables hyper-personalization at scale across mobile, email, web, SMS, and messaging channels.",
    ],
    link: "https://www.moengage.com/",
    grid: 4,
  },
  {
    name: "Logic Serve",
    pageLink: "Logic Serve",
    type: "EXHIBITOR",
    image: "lsdigital.jpg",
    text: [
      `LS Digital is one of the largest integrated digital marketing and transformation companies that offers the most comprehensive suite of digital marketing services. We provide the best-in-class platform, formed by several expert companies to put digital transformation at the heart of our brands by focusing on 6 important areas: Media, UI/UX, Creative & Communication, CX (Martech), Data & Insights, and Tech Innovations.  
<br/><br/>
      Our decade-plus domain experience has enabled us to help our clients bring efficiency in their Digital marketing endeavors. Our award-winning team proactively enables your brand to surpass its goals around brand equity, customer-centricity, and topline revenue. We also work along with our clients as ‘Digital Consultants’ to identify gaps, strategize & implement the right-fit technology stack for the organization just in time for maximum results.  `,
    ],
    link: "https://www.lsdigital.com/",
    grid: 4,
  },
  {
    name: "Bloomreach",
    pageLink: "Bloomreach",
    type: "VISITING PARTNER",
    image: "bloomreach.jpg",
    text: [
      "Bloomreach is the world’s #1 Commerce Experience Cloud, empowering brands to deliver customer journeys so personalized, they feel like magic. It offers a suite of products that drive true personalization and digital commerce growth, including: Discovery, offering AI-driven search and merchandising; Content, offering a headless CMS; and Engagement, offering a leading CDP and marketing automation solutions. Together, these solutions combine the power of unified customer and product data with the speed and scale of AI-optimization, enabling revenue-driving digital commerce experiences that convert on any channel and every journey. Bloomreach serves over 850 global brands including Albertsons, Bosch, Puma, FC Bayern München, and Marks & Spencer.",
    ],
    link: "https://www.Bloomreach.com/",
    grid: 4,
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsorsnew);

export const news = [
  {
    title: "What’s in Store for Influencer Marketing in 2023?",
    image:
      "https://martechvibe.com/wp-content/uploads/New-Ogilvy-report-reveals-6-key-influencer-trends-that-will-define-2023.jpg",
    link: "https://martechvibe.com/martech/whats-in-for-influencer-marketing-in-2023/",
  },
  {
    title: "What CMO’s Need To Focus On In 2023",
    image:
      "https://martechvibe.com/wp-content/uploads/Gartner-Identifies-Top-Trends-to-Impact-CMOs-in-2023.jpg",
    link: "https://martechvibe.com/martech/what-cmos-need-to-focus-on-in-2023/",
  },
  {
    title: "22 Big Product Announcements Of 2022",
    image:
      "https://martechvibe.com/wp-content/uploads/2023-FUTURE-SCOPE-Product-News-2022-Recap11.jpg",
    link: "https://martechvibe.com/martech/22-big-product-announcements-of-2022/",
  },
  {
    title: "Top Five Data Trends That Shaped Mobile App Marketing In 2022",
    image:
      "https://martechvibe.com/wp-content/uploads/Top-Five-Data-Trends-That-Shaped-Mobile-App-Marketing-In-2022.jpg",
    link: "https://martechvibe.com/martech/top-five-data-trends-that-shaped-mobile-app-marketing-in-2022/",
  },
];

export const insights = [
  {
    title: "Show Positive Intent Towards Customers",
    image: "https://martechvibe.com/wp-content/uploads/Steven-Van_2.jpg",
    link: "https://martechvibe.com/staff-articles/show-positive-intent-towards-customers/",
  },
  {
    title: "Quick Commerce Needs to Understand the Value of Consistency",
    image: "https://martechvibe.com/wp-content/uploads/Diego-Tarek-de-Aristegui-interview.jpg",
    link: "https://martechvibe.com/staff-articles/quick-commerce-needs-to-understand-the-value-of-consistency/",
  },
  {
    title: "Are You Taking A New CX Approach for Gen Z?",
    image:
      "https://martechvibe.com/wp-content/uploads/Are-You-Taking-A-New-CX-Approach-for-Gen-Z.jpg",
    link: "https://martechvibe.com/events/are-you-taking-a-new-cx-approach-for-gen-z/",
  },
  {
    title: "We Have A Presence In Every Dubai Food Delivery App",
    image: "https://martechvibe.com/wp-content/uploads/Interview_-LEO-DOVBENKO.jpg",
    link: "https://martechvibe.com/interviews/we-have-a-presence-in-every-dubai-food-delivery-app/",
  },
  {
    title: "Does Fragmentation Impact Your Omnichannel CX?",
    image:
      "https://martechvibe.com/wp-content/uploads/Fragmented-Data-Is-a-Roadblock-For-Omnichannel-CX-Strategy.jpg",
    link: "https://martechvibe.com/cx/does-fragmentation-impact-your-omnichannel-cx/",
  },
  {
    title: "Delight In Diverse Touchpoints",
    image:
      "https://martechvibe.com/wp-content/uploads/CX-NXT-Delight-In-Diverse-Touchpoints-interview-with-Vibha-Thusu.jpg",
    link: "https://martechvibe.com/interviews/delight-in-diverse-touchpoints/",
  },
];
