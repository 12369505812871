import React from "react";

function ProfilePageHeader({ title, bgColor, noPadding }) {
  return (
    <>
      <div
        style={{
          backgroundColor: bgColor,
          marginTop: !noPadding && "6rem",
        }}
        className="section py-4"
        data-parallax={true}
      >
        {title ? <h2 className="text-700 text-uppercase text-center text-dark">{title}</h2> : ""}
      </div>
    </>
  );
}

export default ProfilePageHeader;
